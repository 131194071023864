$(function() {

    var $body = $("body");
    var $html = $("html");
    var $header = $(".header");
    var $footer = $(".footer");
    var $subSidebar = $(".sidebar_sub");
    var $headerNavHeight = $header.height();
    var $specialColor = '#5bb682';

    var $toggleBtn = $(".header__nav-mobile-btn");
    var $menu = $(".header__nav");
    var $navLinks = $(".header__nav-link");
    var $mainpage_logo = $(".main__about-logo");
    var $header_logo = $(".header__logo");
    var $header_mobileLogo = $(".header__mobile-logo");
    var $header_mobileLogoContainer = $(".header__mobile-logo-container");

    var $subMenu = $(".header__sub-nav");
    var $subMenuBtn = $(".header__sub-nav-toggle-btn");

    var mobile_breakpoint = 767;
    var tablet_portrait_breakpoint = 1023
    var tablet_landscape_breakpoint = 1200;
    var mobile_menu_breakpoint = 1359;

    // Полифилл element.closest для IE 11 
    (function(ELEMENT) {
            ELEMENT.matches = ELEMENT.matches || ELEMENT.mozMatchesSelector || ELEMENT.msMatchesSelector || ELEMENT.oMatchesSelector || ELEMENT.webkitMatchesSelector;
            ELEMENT.closest = ELEMENT.closest || function closest(selector) {
                    if (!this) return null;
                    if (this.matches(selector)) return this;
                    if (!this.parentElement) {return null}
                    else return this.parentElement.closest(selector)
                };
    }(Element.prototype));


    // about-us появление картинки
    $(document).ready(function() {
        var $about_us_img = $(".about-us__img-container");
        if(!$about_us_img.length) {
            return false;
        }
        var $about_us_img_top = $about_us_img.offset().top;
        if($about_us_img_top < $(window).height()) {
            $about_us_img.addClass("about-us__img-container_visible");
        } else {
            $about_us_img.viewportChecker({
                classToAdd: 'about-us__img-container_visible'
            });
        }
    });

    // цвета точек в зависимости от прокрутки
    function dotColor() {
        if ($(".fixed-block").length) {
            var screenHeight = $(window).height();
            var scrollHeight = $(window).scrollTop();

            if (scrollHeight < screenHeight) {
                //$(".decor-lines").addClass("decor-lines_dot-white");
            } else {
                //$(".decor-lines").removeClass("decor-lines_dot-white");
            }
        }
    }

    dotColor();

    $(window).on("scroll", function() {
        dotColor();
    });


    $.validate({
        modules : 'file',
        errorMessagePosition : 'inline',
        scrollToTopOnError : true,
        borderColorOnError : '#d0011b',
        validateOnBlur : false,
    });

    $.formUtils.loadModules('file', null, function () {
       console.log('modules loaded...');
       console.log( Object.keys($.formUtils.validators) );
    });


    // отправка данных формы
    $(document).ready(function() {

        var $form = $("form");
        var $submitBtn = $form.find("button[name='submit']");

        if (!$form.length) {
            return false;
        }

        // валидация номера телефона
        $(".input[type='phone']").mask(
            "?(999) 999-9999"
        );

        // $(".input[name=name]").attr("data-validation", "required length");
        // $(".input[name=name]").attr("data-validation-length", "1-50");
        // $(".input[name=name]").attr("data-validation-error-msg", "Данное поле должно содержать от 1 до 50 символов");
        
        // $(".input[name=message]").attr("data-validation", "required");

        // $(".input[name=email]").attr("data-validation", "required email");
        // $(".input[name=email]").attr("data-validation-error-msg", "Данное поле должно содержать корректный e-mail адрес");
        
        // $(".input[name=phone]").attr("data-validation", "required length").attr("data-validation-length", "14");
        // $(".input[name=phone]").attr("data-validation-error-msg", "Данное поле должно содержать корректный номер телефона");
        
        // $(".input[name=letter]").attr("data-validation", "required");
        // $(".input[name=hh-link]").attr("data-validation-error-msg", "Данное поле должно содержать корректный url адрес");

        // отображение имени загруженного в форму файла
        function showUploadedFileName() {

            var $formFileInput = $('#file');
            if(!$formFileInput.length) {
                return false;
            }
            var $formFileName;
            if($('.vacancy-page__form-file-upload-name').length) {
                $formFileName = $('.vacancy-page__form-file-upload-name');
            } else {
                $formFileName = $('.feedback-block__form-file-upload-name');
            }
            var $formFileValue = $formFileName.val();
            $formFileName.html($formFileValue);
            
            
            $formFileInput.change(function() {
                setUploadedFileName();
            })

            function setUploadedFileName() {
                $formFileValue = $formFileInput.val().replace(/C:\\fakepath\\/i, '');
                $formFileName.html($formFileValue);
            }
            setUploadedFileName()
        }
        showUploadedFileName();


        // отправка и обработчик отправки
        $form[0].addEventListener("submit", formHandler);
        var $approve = $form.find("input[name=approve]");

        function checkboxCheck() {
            if(!$approve.is(":checked") && $approve.length) {
                $form[0].removeEventListener("submit", formHandler);
                $submitBtn.attr("disabled", true);
            } else {
                $form[0].addEventListener("submit", formHandler);
                $submitBtn.removeAttr("disabled");
            }
        }
        checkboxCheck();

        $approve.change(function(){
            checkboxCheck();
        });

        function formHandler(event) {
            event.preventDefault();

            var outputData = new FormData($form[0]);

            if($form.find("input[type=file]").length) {
                var file = $("#file")[0].files[0];
                outputData.append("file", file);
            } else {
                var file = "";
            }


            for (var [key, value] of outputData.entries()) { 
                console.log(key, value);
            }

            $.ajax({
                url: submitURL,
                type: "POST",
                data: outputData ,
                processData: false,
                contentType: false,
            })
                .success(function( data ) {
                    const answer = data.status;
                    console.log(answer)
                    if(answer == "true") {
                        $form.html(
                            `<div class="form-success-block"> 
                                <svg class="form-success-block__image">
                                    <use xlink:href="#checkInCircle"></use>
                                </svg>
                                <p class="text text_m form-success-block__message">
                                    Ваше сообщение отправлено. <br> Спасибо!
                                </p>
                            </div>`
                        )
                        $form.animate({
                            opacity: "1",
                        }, 300)
                    } else {
                        console.log("Сервер не принял запрос");
                        formSubmitFail();
                    }
                })
                .fail(function() { 
                    console.log("Фейл отправки");
                    formSubmitFail();
                })

            event.preventDefault();
            return false;
        }

        function formSubmitFail() {
            $form.html(
                `<div class="form-error-block"> 
                    <svg class="form-error-block__image">
                        <use xlink:href="#crossInCircle"></use>
                    </svg>
                    <p class="text text_m form-error-block__message">
                        Что-то пошло не так... <br>
                        <a href="" class="form-error-block__reload">Попробовать снова</a>
                    </p>
                </div>`
            )
        }
    });



    // активный элемент в сайдбаре
    function setActiveSidebarItem() {
        var path = $(location).attr("pathname").slice(1,-5);

        $subSidebar.find(".sidebar__item").each(function(){
            var $this = $(this);

            if ($this.attr("href").slice(0, -5) == path) {
                $this.addClass("sidebar__item_active");
            }
        })
    }
    setActiveSidebarItem();


    // активный элемент в шапке
    function setActiveHeaderItem() {
        var $path = $(location).attr("pathname").slice(1,-5);
        
        $header.find(".header__nav-link").each(function(){
            var $this = $(this);
            var $inlinePath = $this.attr("href").slice(0, -5);

            console.log($inlinePath)


            if ($this.hasClass("header__nav-link_directions") && 
                       ($path == "mobile_ads" ||
                       $path == "communications" ||
                       $path == "video" ||
                       $path == "finance" ||
                       $path == "internet_things")) {
                $this.addClass("header__nav-link_active");
            } else if ($inlinePath == $path && !$this.hasClass("header__nav-link_logo") && $inlinePath != "main") {
                $this.addClass("header__nav-link_active");
            } 
        })
    }
    setActiveHeaderItem();


    // Видео на главной странице
    var mainVideo = $(".main__video")[0];

    function playVid() { 
        mainVideo.play(); 
    }
    function pauseVid() { 
        mainVideo.pause(); 
    }


    // исправляет баг с лого в firefox
    $('.main__block-content').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
        var $this = $(this);

        $this.removeClass("animated");
    });


    // плавное перемещение к якорям
    $body.on("click", ".js-anchor-link", function(e){
        e.preventDefault();

        var $this = $(this);
        var $id = $this.attr("href");
        var $scrollTarget = $($id);

        $("html, body").animate({
            scrollTop: $scrollTarget.offset().top - $headerNavHeight,
        }, 300);

        location.hash = $id;
    });


    // ширина полосы прокрутки
    function scrollbarWidth() {
        var documentHeight = parseInt(document.documentElement.clientHeight);
        var windowsHeight = parseInt(window.innerHeight);
        var scrollbarWidth = windowsHeight - documentHeight;
        return scrollbarWidth;
    }


    // зафиксировать сайдбар
    function sidebarPos() {
        var $scrollHeight = $(window).scrollTop();
        var $windowHeight = $(window).height();
        var $contentHeight = $body.height() - $footer.outerHeight() + scrollbarWidth();

        if ($scrollHeight < $subSidebar.height()) {
            $subSidebar.css({
                top: $windowHeight - $scrollHeight + scrollbarWidth(),
                bottom: ($windowHeight + $subSidebar.height() + scrollbarWidth()) - $scrollHeight
            });
        } else if ($scrollHeight >= $subSidebar.height() && $scrollHeight < $contentHeight) {
            $subSidebar.css({
                top: $header.height(),
                bottom: 0
            });
        } else if ($scrollHeight >= $contentHeight) {
            $subSidebar.css({
                top: -($scrollHeight - $contentHeight - $header.height()),
                bottom: $scrollHeight - $contentHeight + $header.height(),
            });
        }
    }

    $(window).on("scroll", function() {
        sidebarPos();
    });

    sidebarPos();


    // добавить шапке прозрачность при прокрутке
    $(window).on("scroll", function(){
        var $scrollHeight = $(window).scrollTop();

        if (!$header.hasClass("main__header")) {
            if ($scrollHeight > 0) {
                $header.addClass("header_opacity");
            } else {
                $header.removeClass("header_opacity");
            }
        }
    });


    // эффект параллакс
    $('.main__block').each(function(){
        var $this = $(this);
        var $bg = $this.data('bg');

        $this.parallax({
            imageSrc: $bg,
        }); 
    });


    // Рандомная позиция для анимации точек
    function decorLinesTop(min, max) {
        var rand = min - 0.5 + Math.random() * (max - min + 1)
        rand = Math.round(rand);
        return rand;
    }


    $(".decor-lines__point").each(function(){
        var $this = $(this);

        setInterval(function(){
            $this.css({
                top: decorLinesTop(1, 100) + "%",
            });
        },2000);
    });


    $(".header__nav-link").css("opacity", "1");


    // переход на детальную страницу направления
    $body.on("click", ".main__block-link", function(e) {
        
        e.preventDefault();
        
        var $this = $(this);
        var $link = $this.attr("href");

        $this.closest(".main__block-content").addClass("main__block-content_hide");

        //$(".header__nav-link_directions").addClass("header__nav-link_active");

        //$header.addClass("header_opacity").removeClass("header_color_white main__header_logo_hide");
        //$(".main__about-logo-container").remove();
        $(".main__sidebar ").addClass("main__sidebar_hide");

        setTimeout(function() {
            location.href = $link;
        }, 300);
        setTimeout(function() {
            $this.closest(".main__block-content").removeClass("main__block-content_hide");
        }, 600);

        
    });

    if ($header.hasClass("header_load")) {
        $header.removeClass("header_no-bg header_color_white");
    }

    $(".fixed-block__container").addClass("fixed-block__container_visible");


    // главная страница
    if ($("#fullpage").length) {
         $("#fullpage").fullpage({
            //Navigation
            //menu: '#menu',
            lockAnchors: false,
            anchors:['about_page', 'mobileads_page', 'communication_page', 'video_page', 'finance_page', 'internet_things_page', 'content_page'],
            navigation: false,
            navigationPosition: 'right',
            //navigationTooltips: ['firstSlide', 'secondSlide'],
            showActiveTooltip: false,
            slidesNavigation: false,
            slidesNavPosition: 'bottom',

            //Scrolling
            css3: true,
            scrollingSpeed: 600,
            autoScrolling: true,
            fitToSection: false,
            fitToSectionDelay: 1000,
            scrollBar: true,
            //easing: 'easeOut',
            easingcss3: 'ease-out',
            loopBottom: false,
            loopTop: false,
            loopHorizontal: true,
            continuousVertical: false,
            continuousHorizontal: false,
            scrollHorizontally: false,
            interlockedSlides: false,
            dragAndMove: false,
            offsetSections: false,
            resetSliders: false,
            fadingEffect: false,
            //normalScrollElements: '#element1, .element2',
            scrollOverflow: false,
            scrollOverflowReset: false,
            scrollOverflowOptions: null,
            touchSensitivity: 35,
            normalScrollElementTouchThreshold: 5,
            bigSectionsDestination: null,

            //Accessibility
            keyboardScrolling: true,
            animateAnchor: true,
            recordHistory: true,

            //Design
            controlArrows: true,
            verticalCentered: true,
            //sectionsColor: ['#ccc', '#fff'],
            paddingTop: '0',
            paddingBottom: '0',
            //fixedElements: '#header, .footer',
            responsiveWidth: 0,
            responsiveHeight: 0,
            responsiveSlides: false,

            //Custom selectors
            sectionSelector: '.section',
            slideSelector: '.slide',

            lazyLoading: true,

            //events
            onLeave: function(index, nextIndex, direction) {

                $(".decor-lines__point").each(function(){
                    var $this = $(this);

                    $this.css({
                        top: decorLinesTop(1, 100) + "%",
                    });
                });


                if (nextIndex > 1) {
                    //$(".main").find(".decor-lines").addClass("decor-lines_light");
                    $(".main__block_about").addClass("main__block_front-logo main__block_fade-content");
                } else {
                    //$(".main").find(".decor-lines").removeClass("decor-lines_light");
                    $(".main__block_about").removeClass("main__block_front-logo main__block_fade-content");
                }

                if (nextIndex == 1) {
                    if(window.innerWidth <= tablet_landscape_breakpoint) {
                        $(".main__sidebar").css({
                            right: "-20px"
                        });
                    }
                    $(".main__sidebar").removeClass("sidebar_color_white").addClass("main__sidebar_gray-border");
                    $(".main__about-logo").removeClass("main__about-logo_white");
                    $(".main__sidebar-item_active").removeClass("main__sidebar-item_active");
                    $toggleBtn.removeClass("header__nav-mobile-btn_white");
                } else if (nextIndex == 2) {
                    sidebarActiveItemScroll($(".main__sidebar-item:first-child"));
                    $(".main__sidebar").addClass("sidebar_color_white");
                    $(".main__about-logo").addClass("main__about-logo_white");
                    $toggleBtn.addClass("header__nav-mobile-btn_white");
                } else if (nextIndex == 3) {
                    $(".main__sidebar").addClass("sidebar_color_white");
                    $toggleBtn.addClass("header__nav-mobile-btn_white");
                    sidebarActiveItemScroll($(".main__sidebar-item:nth-child(2)"));
                } else if (nextIndex == 4) {
                    $(".main__sidebar").addClass("sidebar_color_white");
                    $toggleBtn.addClass("header__nav-mobile-btn_white");
                    sidebarActiveItemScroll($(".main__sidebar-item:nth-child(3)"));
                } else if (nextIndex == 5) {
                    $(".main__sidebar").addClass("sidebar_color_white");
                    $toggleBtn.addClass("header__nav-mobile-btn_white");
                    sidebarActiveItemScroll($(".main__sidebar-item:nth-child(4)"));
                } else if (nextIndex == 6) {
                    $toggleBtn.addClass("header__nav-mobile-btn_white");
                    $(".main__sidebar").addClass("sidebar_color_white").removeClass("main__sidebar_gray-border");
                    sidebarActiveItemScroll($(".main__sidebar-item:nth-child(5)"));
                }

                if (nextIndex == 2 && direction == "down") {
                    $(".main__about-logo-container").addClass("main__about-logo-container_fixed");
                    getLogoPosition();
                } else if (nextIndex == 1 && direction == "up") {
                    $toggleBtn.removeClass("header__nav-mobile-btn_white");
                    $(".main__about-logo-container").removeClass("main__about-logo-container_fixed");
                    $(".main__about-logo-container").css({
                        top: "auto",
                        left: "auto",
                    });
                }

                if (nextIndex != 1 && nextIndex < 7) {
                    if(window.innerWidth <= tablet_landscape_breakpoint) {
                        $(".main__about-logo").addClass("main__about-logo_white");
                        $(".main__sidebar").css({
                            right: ""
                        });
                    } else {
                        $header.addClass("header_color_white");
                        $(".main__about-logo").removeClass("main__about-logo_white");
                    }
                } else {
                    $header.removeClass("header_color_white");
                    $(".main__about-logo").removeClass("main__about-logo_white");
                }

                if (index == 6 && direction == "down") {
                    $.fn.fullpage.setAutoScrolling(false);
                    $toggleBtn.removeClass("header__nav-mobile-btn_white");
                } else {
                    $.fn.fullpage.setAutoScrolling(true);
                }

                
                if (nextIndex >= 7) {
                    $(".main__sidebar").addClass("main__sidebar_hide");
                    if(window.innerWidth <= tablet_landscape_breakpoint) {
                        $(".main__sidebar").css({
                            right: "-20px"
                        });
                    }
                } else {
                    $(".main__sidebar").removeClass("main__sidebar_hide");
                }


                if (nextIndex == 7 & direction == "down") {
                    //$(".decor-lines").removeClass("decor-lines_light");
                } else {
                   //$(".decor-lines").addClass("decor-lines_light");
                }
            },
            afterLoad: function(anchorLink, index) {
                
                if (index == 1) {
                    $(".main__sidebar-border").addClass("main__sidebar-border_hide");
                    $(".decor-lines").addClass("decor-lines_border-main");
                    $(".main__sidebar").addClass("main__sidebar_gray-border");
                    if(window.innerWidth <= tablet_landscape_breakpoint) {
                        $(".main__sidebar").css({
                            right: "-20px"
                        });
                    }
                } else {
                    $(".main__sidebar-border").removeClass("main__sidebar-border_hide");
                    $(".decor-lines").removeClass("decor-lines_border-main");
                    $(".main__sidebar").removeClass("main__sidebar_gray-border");
                }

                if (index >= 2) {
                    $(".main__about-logo-container").addClass("main__about-logo-container_fixed");
                    getLogoPosition();
                }

                if (index > 1 && mainVideo.length) {
                    pauseVid();
                } else if(mainVideo && window.innerWidth > mobile_breakpoint){
                    playVid();
                }

                if (index == 7) {
                    $.fn.fullpage.setAutoScrolling(false);
                }

                if (index >= 7) {
                    $header.addClass("header_opacity");
                    //$(".decor-lines").removeClass("decor-lines_light");
                } else {
                    $header.removeClass("header_opacity");
                    //$(".decor-lines").addClass("decor-lines_light");
                }
                
                $body.find(".section.active").find(".main__block-content").css("display", "flex").addClass("animated, fadeInUp");
            },
            afterRender: function() {
                if (mainVideo) {
                    playVid();
                }
            },
            afterResize: function() {
                sidebarActiveItemScroll($(".main__sidebar-item_active"));
                if ( $(".main__about-logo-container").hasClass("main__about-logo-container_fixed")) {
                    getLogoPosition();
                }
            },
            afterResponsive: function(isResponsive){},
            afterSlideLoad: function(anchorLink, index, slideAnchor, slideIndex){},
            onSlideLeave: function(anchorLink, index, slideIndex, direction, nextSlideIndex){}
        });
    }
   

    // позиционирование логотипа на главной странице
    function getLogoPosition() {
        if(window.innerWidth <= mobile_breakpoint) {
            var margin = parseInt($(".main__about-logo-container").css("width"))/2;
            $(".main__about-logo-container").css({
                top: '15px',
                left: (window.innerWidth/2 - margin) + 'px',
            });
        } else if(window.innerWidth <= mobile_menu_breakpoint) {
            var margin = parseInt($(".main__about-logo-container").css("width"))/2;
            $(".main__about-logo-container").css({
                top: '37px',
                left: (window.innerWidth/2 - margin) + 'px',
            });
        } else {
            $(".main__about-logo-container").css({
                top: $(".header__nav-item:nth-child(4)").position().top,
                left: $(".header__nav-item:nth-child(4)").offset().left,
            });
        }
    }
    

    // перемещение рамки в сайдбаре
    function sidebarActiveItemScroll(item) {
        var $this = item;
        var $itemPosition = $this.position().top;

        if (!$this.hasClass("main__sidebar-item_active")) {
            $(".main__sidebar-item_active").removeClass("main__sidebar-item_active");
            $this.addClass("main__sidebar-item_active");
            $(".main__sidebar").find(".main__sidebar-border").css("top", $itemPosition);
        } else {
            $(".main__sidebar").find(".main__sidebar-border").css("top", $itemPosition);
        }
    }


    // клик по пункту в сайдбаре
    $body.on("click", ".main__sidebar-item", function(e) {
        var $this = $(this);
        sidebarActiveItemScroll($this)
    });


    $body.on("click", ".js-btn-top", function(e){
        e.preventDefault();
        $("html, body").animate({
            scrollTop: 0
        }, 300);
    });


    // фильтр вакансий
    var $filterButtons = $(".vacancies__filter-item");
    var $enableAllButton = $(".vacancies__filter-item[data-filter='all']");

    $filterButtons.click(function(event) {

        var button = event.target;
        $(button).toggleClass("vacancies__filter-item_enabled");

        var enabledButtons = $(".vacancies__filter-item_enabled");

        if($(button).attr("data-filter") == "all" || !enabledButtons[0]) {
            $filterButtons.removeClass("vacancies__filter-item_enabled")
            $enableAllButton.addClass("vacancies__filter-item_enabled");
        } else {
            $enableAllButton.removeClass("vacancies__filter-item_enabled");
        }
        filterVacancies();
    });
    
    function filterVacancies() {
        var $vacancies = $(".vacancy");
        var $enabledButtons = $(".vacancies__filter-item_enabled");
        if($enableAllButton.hasClass("vacancies__filter-item_enabled")) {
            $vacancies.show();
            return;
        }
        $vacancies.hide();
        $enabledButtons.each(function(index, filter) {
            var filterValue = $(filter).attr("data-filter");
            console.log(filterValue)

            $vacancies.each(function(index, vacancy) {
                var vacancyType = $(vacancy).attr("data-filter");
                if(filterValue == vacancyType) {
                    $(vacancy).show()
                }
            });
        })
    }

    $(document).ready(function() {
        $('img[src$=".svg"]').each(function() {
            var $img = jQuery(this);
            var imgURL = $img.attr('src');
            var attributes = $img.prop("attributes");

            $.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Remove any invalid XML tags
                $svg = $svg.removeAttr('xmlns:a');

                // Loop through IMG attributes and apply on SVG
                $.each(attributes, function() {
                    $svg.attr(this.name, this.value);
                });

                // Replace IMG with SVG
                $img.replaceWith($svg);
            }, 'xml');
        });
    });

    // кнопка "ещё" в новостях
    $(window).load(function () {
        var $newsBtn = $(".news__btn-more");
        var $news = $(".article-list_news .article-list__item");
        var $newsCount = $news.length;
        var $container = $(".article-list_news");
        var defaultCount = 5;
        if(window.innerWidth <= tablet_portrait_breakpoint) {
            defaultCount = 3;
        }
        var savedOpen = parseInt(localStorage.getItem("_news")) || defaultCount;
        var showCount = savedOpen;
        if($newsCount >= showCount) {
            $newsBtn.css({
                "display": "block",
            })
        }

        var $shownNews = $news.slice(0, savedOpen);

        var containerHeight;

        function countHeight() {
            containerHeight = 0;
            $shownNews.each(function() {
                var $item = $(this);
                containerHeight += $item.height();
            });
            if(showCount >= $newsCount) {
                $newsBtn.html("Скрыть")
            }
        }
        function setHeight(duration = 800) {
            $container.animate({
                "height" : containerHeight,
            }, duration)
        }

        countHeight();
        setHeight(0);

        function showMore() {
            $shownNews = $news.slice(0, showCount);
            countHeight();
            setHeight();
        }
        function hideAll() {
            showCount = defaultCount;
            $shownNews = $news.slice(0, showCount);
            countHeight();
            $('html, body').animate({
                scrollTop: $container.offset().top - 350,
            }, 1000);
            setHeight(1000);
        }

        $newsBtn.on("click", function() {
            if(showCount >= $newsCount) {
                hideAll();
                $newsBtn.html("Еще")
                localStorage.setItem("_news", defaultCount)
            } else {
                showCount += defaultCount;
                localStorage.setItem("_news", showCount);
                showMore();
                if(showCount >= $newsCount) {
                    $newsBtn.html("Скрыть")
                }
            }
        });
    });


    // кнопка "ещё" на главной на случай если понадобится отображать на ней все новости
    
    // $(window).load(function () {
    //     var $newsBtn = $(".main__news_btn-more");
    //     var $news = $(".main__news .article-list__item");
    //     var $newsCount = $news.length;
    //     var $container = $(".main__news");
    //     var defaultCount = 3;
    //     var savedOpen = parseInt(localStorage.getItem("_news-main")) || defaultCount;
    //     console.log(localStorage.getItem("_news-main"))
    //     var showCount = savedOpen;
    //     if($newsCount >= showCount) {
    //         $newsBtn.css({
    //             "display": "block",
    //         })
    //     }

    //     var $shownNews = $news.slice(0, savedOpen);

    //     var containerHeight;

    //     function countHeight() {
    //         containerHeight = 0;
    //         $shownNews.each(function() {
    //             var $item = $(this);
    //             containerHeight += $item.height();
    //         });
    //         if(showCount >= $newsCount) {
    //             $newsBtn.html("Скрыть")
    //         }
    //     }
    //     function setHeight(duration = 800) {
    //         $container.animate({
    //             "height" : containerHeight,
    //         }, duration)
    //     }

    //     countHeight();
    //     setHeight(0);

    //     function showMore() {
    //         $shownNews = $news.slice(0, showCount);
    //         countHeight();
    //         setHeight();
    //     }
    //     function hideAll() {
    //         showCount = defaultCount;
    //         $shownNews = $news.slice(0, showCount);
    //         countHeight();
    //         $('html, body').animate({
    //             scrollTop: $container.offset().top - 280,
    //         }, 1000);
    //         setHeight(1000);
    //     }

    //     $newsBtn.on("click", function() {
    //         if(showCount >= $newsCount) {
    //             hideAll();
    //             $newsBtn.html("Еще")
    //             localStorage.setItem("_news-main", defaultCount)
    //         } else {
    //             showCount += defaultCount;
    //             localStorage.setItem("_news-main", showCount);
    //             showMore();
    //             if(showCount >= $newsCount) {
    //                 $newsBtn.html("Скрыть")
    //             }
    //         }
    //     });
    // });




// Скрипты для работы мобильной версии -----------------------------------------




    //Кнопка скролла в начало страницы
    var $scrollTopBtn = $(".scroll-top-btn");

    $scrollTopBtn.click(function() {
        $(window)[0].removeEventListener("scroll", scrollTopBtnToggle);
        scrollTopBtnHide();
        setTimeout(function() {
            $(window)[0].addEventListener("scroll", scrollTopBtnToggle);
        }, 800)
        $('html, body').animate({
            scrollTop: "0",
        }, 800, "swing");
    });

    function getDocHeight() {
        var D = document;
        return Math.max(
            D.body.scrollHeight, D.documentElement.scrollHeight,
            D.body.offsetHeight, D.documentElement.offsetHeight,
            D.body.clientHeight, D.documentElement.clientHeight
        );
    }


    function scrollTopBtnToggle() {
        var $windowHeight = $(window).height();
        var $mainpageContent = $(".main__content-block");
        if($mainpageContent.length) {
            if($(window).scrollTop() >= $mainpageContent.offset().top) {
                scrollTopBtnShow();
            } else {
                scrollTopBtnHide();
            }
        } else {
            if($(window).scrollTop() >= $windowHeight) {
            scrollTopBtnShow();
            } else {
                scrollTopBtnHide();
            }
        }

        if($(window).scrollTop() + $(window).height() >= getDocHeight() - $footer.outerHeight()) {
            $scrollTopBtn.css({
                right: "20px"
            })
        } else {
            $scrollTopBtn.css({
                right: ""
            })
        }
    }

    function scrollTopBtnShow() {
        $scrollTopBtn.addClass("scroll-top-btn_visible")
    }
    function scrollTopBtnHide() {
        $scrollTopBtn.removeClass("scroll-top-btn_visible")
    }

    $(window)[0].addEventListener("scroll", scrollTopBtnToggle);
    document.addEventListener("DOMContentLoaded", scrollTopBtnToggle);





    // Открытие/закрытие меню
    $toggleBtn.click(menuVisibilityToggle);
    $navLinks.click(menuClose);

    // Если у шапки прозрачный фон, он белеет, для этого нужна задержка
    function menuVisibilityToggle() {
        if(!$menu.hasClass("opened")) {
            if($header.hasClass("main__header") && !($(".main__about-logo-container_fixed").length)) {
                $header_mobileLogoContainer.css({
                    display: "block",
                    opacity: "0"
                })
                $header_mobileLogoContainer.animate({
                    opacity: "1"
                }, 300)
            }
            if($header.hasClass("header_opacity") && $header.hasClass("main__header")) {
                menuOpen(120);
            } else if(!($header.hasClass("main__header"))) {
                menuOpen(120);
            } else {
                menuOpen(420);
            }
        } else {
            if($header.hasClass("main__header") && !($(".main__about-logo-container_fixed").length)) {
                $header_mobileLogoContainer.animate({
                    opacity: "0"
                }, 300)
                setTimeout(function() {
                    $header_mobileLogoContainer.css({
                        display: "",
                    })
                }, 300)
                
            }
            if($header.hasClass("header_opacity") && $header.hasClass("main__header")) {
                menuClose(120);
            } else if(!($header.hasClass("main__header"))) {
                menuClose(120);
            } else {
                menuClose(400);
            }
        }
    }

    function menuOpen(duration = 420) {
        if ($("#fullpage").length) {
            $.fn.fullpage.setKeyboardScrolling(false);
            $.fn.fullpage.setAllowScrolling(false);
            // $.fn.fullpage.setAutoScrolling(false);
            
        }
        $header.addClass("header_bg_white");
        if($mainpage_logo.length) {
            $mainpage_logo.css({
                fill: $specialColor
            })
        }
        $toggleBtn.addClass("header__nav-mobile-btn_gray");
        setTimeout(function() {
            $menu.addClass("opened");
            setTimeout(function() {
                $body.css({
                    overflow: "hidden",
                    position: "absolute",
                });
                $html.css({
                    overflow: "hidden",
                    position: "absolute",
                })
            }, 400);
        }, duration);
        setTimeout(function() {
            $toggleBtn.addClass("header__nav-mobile-btn_cross");
            $scrollTopBtn.css({
                bottom: -1*($scrollTopBtn.outerHeight()),
            })
        }, duration);
    }

    function menuClose(duration = 400) {
        $body.css({
            overflow: "visible",
            position: "relative",
        })
        $html.css({
            overflow: "visible",
            position: "relative",
        })
        if ($("#fullpage").length) {
            $.fn.fullpage.setKeyboardScrolling(true);
            $.fn.fullpage.setAllowScrolling(true);
            // $.fn.fullpage.setAutoScrolling(true);
            
        }
        setTimeout(function() {
            $header.removeClass("header_bg_white");
            if($mainpage_logo.length) {
                $mainpage_logo.css({
                    fill: ''
                })
            }
            $toggleBtn.removeClass("header__nav-mobile-btn_cross");
            $toggleBtn.removeClass("header__nav-mobile-btn_gray");
            $scrollTopBtn.css({
                bottom: ""
            })
        }, duration);
        $menu.removeClass("opened");
        setTimeout(function() {
            subMenuClose(0)
        }, duration)
    }



    // Мобильное подменю для направлений
    var subMenuHeight = $subMenu.height();
    subMenuClose(0);
    var subMenuClosed = true;
    $subMenuBtn[0].addEventListener("click", subMenuVisibilityToggle);

    function subMenuVisibilityToggle() {
        if(subMenuClosed) {
            subMenuOpen();
        } else {
            subMenuClose();
        }
    }

    function subMenuClose(duration = 400) {
        $subMenu.animate({
            height: 0,
        }, duration)
        subMenuClosed = true;
        $subMenuBtn.removeClass("header__sub-nav-toggle-btn_close")
    }

    function subMenuOpen(duration = 400) {
        $subMenu.animate({
            height: subMenuHeight,
        }, duration)
        subMenuClosed = false;
        $subMenuBtn.addClass("header__sub-nav-toggle-btn_close");
    }



    // Карусель для страницы "Интернет вещей"
    var $currentSlideNumberContainer = $('.internet-things__products-slick-slide-current');
    var $totalSlidesNumberContainer = $('.internet-things__products-slick-slide-total');
    var $slickElement = $(".internet-things__products");

    $slickElement.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
        var i = (currentSlide ? currentSlide : 0) + 1;
        $currentSlideNumberContainer.html(i);
        $totalSlidesNumberContainer.html(slick.slideCount);
    });

    $slickElement.slick({

        infinite: false,
        arrows: true,
        prevArrow: `<svg class="slick-prev">
                        <use xlink:href="#directionLeft"></use>
                    </svg>`,
        nextArrow: `<svg class="slick-next">
                        <use xlink:href="#directionRight"></use>
                    </svg>`,

        responsive: [{
            breakpoint: 10000,
            settings: "unslick"
        },
        {
            breakpoint: mobile_breakpoint,
            slidesToShow: 1,
        }]
    });

    // Видео на главной заменяется на картинку в мобильной версии
    var $mainpageVideo = $(".main__video");

    function setVideo() {
        if(!$mainpageVideo.length) {
            return false;
        }
        if(window.innerWidth <= mobile_breakpoint) {
            disableMainpageVideo();
        } else {
            enableMainpageVideo();
        }
    }

    function enableMainpageVideo() {
        $mainpageVideo.find("source").attr("src", "video/main_intro.mov");
        $mainpageVideo[0].load()
    }

    function disableMainpageVideo() {
        $mainpageVideo.find("source").attr("src", "");
    }

    $(document).ready(function() {setVideo()})
    $(window).on("resize", function() {
        setVideo();
    });
});

